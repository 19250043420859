
const home = {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  }
}
export default home
