<template>
  <div id="logo">
    <!-- <img src="./../../assets/logo.png" alt=""> -->
    <h1>智能门锁管理后台</h1>
  </div>
</template>

<script>
export default {
  name: 'logo'
}
</script>

<style>
#logo{
  width: 100%;
  height: 100%;
}
#logo h1{
  /* text-indent: -9999px; */
  overflow: hidden;
  /* background: url(./../../assets/logo.png) center no-repeat; */
}
</style>
