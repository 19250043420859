import vue from 'vue'
import VueRouter from 'vue-router'
import routes from './router'

vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  routes // 必须是routes 不能用其他名字
})

export default router
