<template>
  <div id="sideNav">
    <div class="nav">
      <el-menu :default-active="getSideNavNode" class="el-menu-vertical" background-color="#F5F5F5" text-color="#333" active-text-color="#333" :collapse="isCollapse" @open="handleOpen" @close="handleClose">
        <el-row class="tac">
          <el-col>
            <template v-for="router of routerConfig">
              <!-- <template v-if="router.meta.isShow"> -->
              <template v-if="router.children">
                <menuGroupItem :router="router" :key="router.key" :index="0" :role="role" :checkRole="checkRole"></menuGroupItem>
              </template>
              <template v-else>
                <menuItem v-if="checkRole(router.roles, role)" :path="router.path" :title="router.title" :name="router.key" :key="router.key" :index="0">
                </menuItem>
              </template>
            </template>
            <!-- </template> -->
          </el-col>
        </el-row>
      </el-menu>
    </div>
  </div>
</template>
<script>
// import { mapGetters, mapMutations } from 'vuex'
import routerConfig from '@/router/routerConfig'
import { mapGetters } from 'vuex'
export default {
  name: 'sideNav',
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    // ...mapGetters(['getCollapse'])
    ...mapGetters(['getSideNavNode', 'getItem'])
  },
  data: function () {
    return {
      isCollapse: false,
      routerConfig,
      role: '0'
    }
  },
  created () {
    this.isCollapse = this.getCollapse
    this.role = this.getItem('user').userRole
  },
  methods: {
    handleOpen (key, keyPath) {
      console.log(key, keyPath, this.isCollapse)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath, this.isCollapse)
    },
    checkRole (roles, role) {
      console.log('role: ', role);
      console.log('roles: ', roles);
      if (!roles) {
        return true
      }
      roles = Array.prototype.slice.apply(roles, [0])
      return roles.indexOf(role) >= 0
    }
  }
}
</script>
<style lang="scss">
/* element 菜单右侧有一个1px的边框,取消掉 */
.el-menu {
  border: none;
}

/* 选中后修改背景颜色 */
.el-menu-item.is-active {
  background-color: #E0E0E0 !important;
  border-radius: 4px;
}
</style>
