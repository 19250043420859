<template>
  <div
    class="pc-loadding c2"
    v-loading="getLoaddingState"
    element-loading-spinner="el-icon-loading"
    element-loading-text="加载中，请稍等..."
    element-loading-background="rgba(255, 255, 255, 0.6)"
  >
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'loadding',
  computed: {
    ...mapGetters(['getLoaddingState'])
  }
}
</script>

<style>
.pc-loadding {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  font-size: 25px;
}
/* 设置字体颜色 */
.pc-loadding .el-loading-text {
  color: #219878;
}

/* 设置icon图标的颜色 */
.pc-loadding .el-icon-loading {
  color: #219878;
  font-size: 40px;
}
</style>
