const userUtil = {
  state: {
    storageKey: 'equipment_key'
  },
  getters: {
    /**
     * @name 获取缓存内容
     * @param {key} key 存储的key值
     */
    getItem: state => key => {
      if (!key) { return }
      const _storageKey = JSON.parse(
        window.localStorage.getItem(state.storageKey) || '{}'
      )
      return _storageKey[key] || ''
    }
  },
  mutations: {
    /**
     * @name 添加缓存到localStorage
     * @param {key} opts 存储的名称
     * @param {value} opts 需要存储的值
     */
    saveItem (state, opts) {
      if (!opts.key) { return }
      const _storageKey = JSON.parse(
        window.localStorage.getItem(state.storageKey) || '{}'
      )
      _storageKey[opts.key] = opts.value
      window.localStorage.setItem(
        state.storageKey,
        JSON.stringify(_storageKey)
      )
    },
    /**
    * @name 清楚缓存
    * @param {key} key 存储的名称
    */
    clearItem (state, key) {
      if (!key) {
        return
      }
      const _storageKey = JSON.parse(
        window.localStorage.getItem(state.storageKey) || '{}'
      )
      if (_storageKey[key]) {
        delete _storageKey[key]
      }
      window.localStorage.setItem(
        state.storageKey,
        JSON.stringify(_storageKey)
      )
    }
  },
  actions: {
    // 登录
    login: ({ dispatch, commit }, opts) => {
      return new Promise((resolve, reject) => {
        const that = opts.vue
        dispatch('send', {
          method: 'post',
          url: 'login',
          ...opts
        }).then((res) => {
          const data = res.data
          if (data.code === 0) {
          // 请求成功，保存token
            const user = data.data
            commit('saveItem', {
              key: 'user',
              value: user
            })
            resolve(data.message)
          }
          if (data.code === 1) {
            that.$message.success(data.message)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    }
  }
}

export default userUtil
