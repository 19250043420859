<template>
  <router-link :to="path">
    <el-menu-item :index="name">
      <!-- <i class="icon iconfont">&#xe6dc;</i> -->
      <span slot="title">{{title}}</span>
    </el-menu-item>
  </router-link>
</template>

<script>
export default {
  name: 'menuItem',
  props: {
    index: {
      type: Number,
      required: false
    },
    path: {
      // 唯一的routerPath
      type: String,
      required: false
    },
    title: {
      // 标题
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data () {
    return {}
  }
}
</script>

<style>

</style>
