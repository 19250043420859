<template>
  <!-- <el-menu-item :index="router.name" @click="test">
    <span slot="title">{{router.title}}</span>
  </el-menu-item>-->
  <div class="mentGroup">
    <el-submenu :index="router.key" v-if="checkRole(router.roles, role)">
      <div slot="title" class="group_title">
        <!-- <i class="icon iconfont">&#xe6dc;</i> -->
        <span slot="title">{{ router.title }}</span>
      </div>
      <template v-for="item of router.children">
        <menuGroupItem v-if="item.children" :router="item" :key="item.key" :index="index + 1" :role="role" :checkRole="checkRole"></menuGroupItem>
        <template v-else>
          <el-menu-item-group v-if="checkRole(item.roles, role)" :key="item.key">
            <menuItem :path="item.path" :title="item.title" :name="item.key" :index="index + 1">
            </menuItem>
          </el-menu-item-group>
        </template>
      </template>
    </el-submenu>
  </div>
</template>

<script>
export default {
  name: 'menuGroupItem',
  props: {
    index: {
      type: Number,
      required: true
    },
    router: {
      type: Object,
      required: true
    },
    role: {
      type: String,
      require: true
    },
    checkRole: {
      type: Function,
      require: true
    }
  },
  data () {
    return {}
  }
}
</script>

<style>
</style>
