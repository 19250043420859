<template>
  <div id="header">
    <div class="flex justify-between">
      <div class="logo">
        <logo/>
      </div>
      <div class="userNameInfo">
        <ul>
          <li>
            <el-dropdown @command="handleCommand">
              <a href="#">
                <span>
                  <span>您好，{{this.getItem('user').name}}</span>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="logout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </a>
            </el-dropdown>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'Header',
  data: function () {
    return {}
  },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(['getItem'])
  },
  methods: {
    ...mapMutations(['clearItem']),
    handleCommand (command) {
      if (command === 'logout') {
      // 退出登录,清除用户信息,跳转到登录页面
        this.clearItem('user')
        this.$message.success('退出成功')
        this.$router.push({ path: '/login' })
      }
    }
  }
}
</script>

<style>
#header li {
  margin-right: 10px;
  font-size: 14px;
}
#header li .avatar {
  width: 40px;
  height: 40px;
  /* margin-top: 10px; */
  border-radius: 50%;
  background-size: 100% 100%;
}
#header .logo {
  width: 200px;
}
#header .userNameInfo {
  text-align: right;
  margin-right: 20px;
  line-height: 60px;
}

#header .userNameInfo span {
  font-size: 16px;
}

#header .iconfont.userIcon {
  margin-left: 10px;
  font-size: 20px;
  display: inline-block;
  vertical-align: middle;
}
</style>
