<template>
  <div id="foot">
    <div class="copyright">Copyright©{{date}} {{company}}技术部出品</div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'Foot',
  data: function () {
    return {
      company: '',
      date: moment().format('YYYY')
    }
  }
}
</script>

<style>
#foot {
  text-align: center;
  line-height: 60px;
  height: 60px;
}

#foot .copyright {
    font-size: 14px;
    color: rgba(0,0,0,.45);
  }
</style>
