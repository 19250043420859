import Vue from 'vue'
import Vuex from 'vuex'
import userUtil from './user'
import home from './home'
import commonUnit from './common'
import ajax from './ajax'

Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    userUtil,
    home,
    commonUnit,
    ajax
  }
})
export default store
