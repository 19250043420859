const commonUnit = {
  state: {
    isLoadding: false,
    sideNavNode: 'home', // 默认侧边栏选中home标签
    routerPath: '' // 需要跳转的路由
  },
  getters: {
    getLoaddingState (state) {
      return state.isLoadding
    },
    getSideNavNode (state) { return state.sideNavNode },
    getRouterPath (state) { return state.routerPath }
  },
  mutations: {
    changLoadding (state, loadding) { state.isLoadding = loadding },
    changeSideNav (state, sideNavNode) {
      state.sideNavNode = sideNavNode
    }
  }
}
export default commonUnit
