<template>
  <div id="app">
    <!-- <div> -->
    <el-container v-if="$route.meta.keepAlive">
      <el-header>
        <Header></Header>
      </el-header>
      <el-container>
        <el-aside width="240px">
          <sideNav></sideNav>
        </el-aside>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <loadding v-if="getLoaddingState"></loadding>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import routerConfig from './router/routerConfig'
export default {
  name: 'App',
  computed: {
    ...mapGetters(['getLoaddingState', 'getRouterPath', 'getItem', 'getUrls'])
  },
  created () {
    this.checkToken()
  },
  methods: {
    ...mapMutations(['changeSideNav']),
    ...mapActions(['send']),
    handleSideNavNode (path) {
      let sideNavNode = ''
      const findIndex = (routers, url) => {
        for (const row of routers) {
          if (row.path) {
            if (row.path === url) {
              sideNavNode = row
              return sideNavNode
            }
          } else {
            if (row.children) {
              sideNavNode = findIndex(row.children, url)
            }
          }
        }
        return sideNavNode
      }
      sideNavNode = findIndex(routerConfig, path)
      return sideNavNode
    },
    checkRole (roles) {
      const user = this.getItem('user')
      const role = user.userRole
      if (!roles) {
        return true
      }
      roles = Array.prototype.slice.apply(roles, [0])
      return roles.indexOf(role) >= 0
    },
    checkToken () {
      console.log("checktoken");
      this.send({
        vue:this,
        url:this.getUrls.checkToken,
        data: {}
      }).then(() => {

      })
    }
  },
  watch: {
    $route: {
      handler (to) {
        if (to) {
          const sideNavNode = this.handleSideNavNode(to.fullPath)
          console.log(sideNavNode, 'sideNavNode');
          if (!this.checkRole(sideNavNode.roles)) {
            this.$message.error('您暂无权限查看该页面')
            this.$router.replace({ path: '/403' })
          }
          this.changeSideNav(sideNavNode.key)
        }
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Noto Sans S Chinese;
}

.el-header {
  background-color: #fff;
  color: #333;
  padding: 0px;
  text-align: center;
  line-height: 60px;
  box-shadow: 0px 2px 4px 0px rgba(224, 224, 224, 0.5);
  z-index: 2;
}

#app .el-menu-item {}

.el-container {
  /* overflow: hidden; */
}

.el-main {
  color: #333;
  text-align: left;
  background-color: #f5F5F5;
  height: calc(100vh - 61px);
  min-width: 1080px;
  /* width: calc(100vw - 200px); */
  /* margin-top: 4px; */
  /* display: none; */
}

.el-aside {
  color: #333;
  padding: 0 5px;
  border-right: solid 1px rgba(5, 5, 5, 0.06);
  background-color: #f5F5F5;
  text-align: left;
  height: calc(100vh - 60px);
  box-shadow: 2px 0px 10px #f3f3f3;
  z-index: 2;
}
</style>
