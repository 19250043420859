// 侧边栏菜单配置

/**
    key: ""  // 侧边栏唯一key全局不重复
    title: "", // title 展示在侧边栏的问题
    roles: ["1", "2"], // 当前侧边栏能够查看的权限
    children: [ // 子菜单配置
      {
        path: "/rule/special", // 路由地址
        key: "special", // 侧边栏唯一key全局不重复
        title: "特殊",// title 展示在侧边栏的标题
        roles: ["1", "2"], // 当前侧边栏能够查看的权限
      },
    ]
 */
export default [
  {
    path: "/home",
    key: "home",
    title: "首页",
  },
  {
    path: "/lock",
    key: "lock",
    title: "门锁管理",
    roles: [1, 2],
    children: [
      // 子菜单配置
      {
        path: "/lock/equipment",
        key: "equipment",
        title: "设备管理",
        roles: [1, 2],
      },
      {
        path: "/lock/password",
        key: "password",
        title: "密码管理",
        roles: [1, 2],
      },
      {
        path: "/lock/link",
        key: "link",
        title: "动态链接",
        roles: [1, 2],
      },
      {
        path: "/lock/logs",
        key: "logs",
        title: "设备日志",
        roles: [1, 2],
      },
      // {
      //   path: "/lock/locklog",
      //   key: "locklog",
      //   title: "开锁记录",
      // },
    ],
  },
  {
    path: "/auth",
    key: "auth",
    title: "设备授权",
    roles: [1, 2],
  },
  {
    path: "/users",
    key: "users",
    title: "用户管理",
    roles: [1],
  },
  {
    path: "/set",
    key: "set",
    title: "系统配置",
    roles: [1,2],
  },
];
