// 路由配置
export default [
  {
    path: "/login",
    meta: {
      keepAlive: false, // 该页面是否需要展示侧边栏
    },
    component: () => import("@/pages/auth/login.vue"),
  },
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/pages/home/home.vue"),
  },
  // 设备管理
  {
    path: "/lock/equipment",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/pages/equipment/index.vue"),
  },
  // 密码管理
  {
    path: "/lock/password",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/pages/equipmentPassword/index.vue"),
  },
  // 链接关系
  {
    path: "/lock/link",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/pages/equipmentLink/index.vue"),
  },
  // 密码管理
  {
    path: "/lock/logs",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/pages/equipmentLog/index.vue"),
  },
  // permission 设备授权表
  {
    path: "/auth",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/pages/equipmentAuth/index.vue"),
  },
  // 用户管理
  {
    path: "/users",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/pages/users/users.vue"),
  },
  // 系统配置
  {
    path: "/set",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/pages/set/index.vue"),
  },
  // 404
  {
    path: "/403",
    meta: {
      keepAlive: true,
    },
    component: () => import("@/pages/errorPage/403.vue"),
  },
];
