import Vue from 'vue'
import App from './App.vue'
import router from './router'
import element from 'element-ui'
import store from './store'
// import axios from './axios'
import components from '@/components'
import 'element-ui/lib/theme-chalk/index.css'
// import './style/init.css'
import dayjs from 'dayjs'
import VueQr from 'vue-qr'
import './style/global.scss'
// import "tailwindcss/tailwind.css"
// import utc from 'dayjs/plugin/utc'
// dayjs.extend(utc)
import utc from 'dayjs/plugin/utc' // import plugin
// import timezone from 'dayjs/plugin/timezone';
// dayjs.extend(timezone);
dayjs.extend(utc) // use plugin



Vue.config.productionTip = false
document.title = '智能门锁管理后台'

Vue.use(element)
Vue.use(VueQr)

// 循环注册组件
components.map(component => Vue.component(component.name, component))
Vue.config.productionTip = false
// Vue.prototype.$http = axios

Vue.filter('percentage', function (value) {
  return `${(value * 100).toFixed(2)}%`
})

Vue.filter('format', function (date) {
  if (date) {
    // return dayjs.tz(date, 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss')
    return dayjs.utc(date).local().format('YYYY-MM-DD HH:mm:ss.SSS')
  } else {
    return ''
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
