// import element from './element/'
import Foot from './layout/Foot'
import Header from './layout/Header'
import SideNav from './layout/SideNav/'
import MentItem from './layout/SideNav/menuItem'
import MenuGroupItem from './layout/SideNav/menuGroupItem'
import Logo from './logo/logo'
import loadding from './loadding/loadding'

// 加载组件
const component = [
  Foot,
  Header,
  SideNav,
  Logo,
  MentItem,
  MenuGroupItem,
  loadding
]

export default component
