import axios from "axios";

axios.defaults.baseURL = "https://d.nbtag.com/api";
// axios.defaults.baseURL = "http://localhost:38080/api";

const ajax = {
  state: {
    baseUrl: 'https://d.nbtag.com',
    // baseUrl: "http://localhost:38080",
    urls: {
      login: { path: "/login", method: "POST", isAuth: true },
      // 通用接口
      findData: { path: "/find/data", method: "POST", isAuth: false },
      // 字典相关
      findDictionary: {
        path: "/find/dictionary",
        method: "POST",
        isAuth: false,
      },
      // 设备相关
      findEquipmentOne: {
        path: "/find/equipment/one",
        method: "POST",
        isAuth: false,
      },
      createEquipment: {
        path: "/create/equipment",
        method: "POST",
        isAuth: false,
      },
      updateEquipment: {
        path: "/update/equipment",
        method: "POST",
        isAuth: false,
      },
      // 密码相关的接口
      createPassword: {
        path: "/create/password",
        method: "POST",
        isAuth: false,
      },
      stopPassword: { path: "/stop/password", method: "POST", isAuth: false },
      updatePassword: {
        path: "/update/password",
        method: "POST",
        isAuth: false,
      },
      // 设备授权相关接口 create/equipment/auth
      createAuth: {
        path: "/create/equipment/auth",
        method: "POST",
        isAuth: false,
      },
      // 设备链接相关接口
      createLink: { path: "/create/link", method: "POST", isAuth: false },
      stopLink: { path: "/stop/link", method: "POST", isAuth: false },
      findLinkOne: { path: "/find/link/one", method: "POST", isAuth: false },
      openLock: { path: "open/lock", method: "POST", isAuth: false },
      // 用户相关接口
      findUsers: { path: "/find/users", method: "POST", isAuth: false },
      createUser: { path: "/create/users", method: "POST", isAuth: false },
      checkToken: { path: "/check/token", method: "POST", isAuth: false },
      findUserOne: { path: "/find/user/one", method: "POST", isAuth: false },
      frozenUser: { path: "/frozen/user", method: "POST", isAuth: false },
      updateUser: { path: "/update/user", method: "POST", isAuth: false },
      // 邮箱相关
      sendEmail: { path: "/send/email", method: "POST", isAuth: false },
      // 系统配置相关
      setConfig: {
        path: "/set/config",
        method: "POST",
        isAuth: false,
        type: "file",
      },
      getConfig: { path: "/get/config", method: "POST", isAuth: false },
    },
  },
  getters: {
    getUrls(state) {
      return state.urls;
    },
    getBaseUrl(state) {
      return state.baseUrl;
    },
  },
  mutations: {},
  actions: {
    // 请求静态字段
    sendFile(vue, opts) {
      axios.get({ url: opts.url });
    },
    // 发送请求
    send({ getters, commit, state, dispatch }, opts) {
      const { data, url, isLoadding = true, warnMessage = true } = opts;
      if (isLoadding) {
        setTimeout(() => {
          commit("changLoadding", true);
        }, 100);
      }
      const that = opts.vue;
      // 获取url的详细请求地址
      const _url = url.path;
      const method = url.method;
      return new Promise((resolve, reject) => {
        const headers = { "Content-Type": "application/json;charset=UTF-8" };
        if (url.type === "file") {
          headers["Content-Type"] = "application/x-www-form-urlencoded";
        }
        const user = getters.getItem("user");
        const { token } = user;
        if (!token && !url.isAuth) {
          that.$router.replace({ path: "/login" });
          setTimeout(() => {
            commit("changLoadding", false);
          }, 100);
        } else {
          headers.token = token;
          const params = { method, url: _url, headers };
          if (method === "POST") params.data = data;
          if (method === "GET") params.params = data;
          axios(params)
            .then((res) => {
              // 关闭加载中
              setTimeout(() => {
                commit("changLoadding", false);
              }, 100);
              if (res) {
                if (res.data.code === 0) {
                  if (url.isAuth) {
                    commit("saveItem", {
                      key: "user",
                      value: res.data.data,
                    });
                  }
                  resolve(res);
                }
                if (res.data.code === 1) {
                  that.$message.error(res.data.msg);
                  reject(res.data.msg);
                }
              }
            })
            .catch((err) => {
              setTimeout(() => {
                commit("changLoadding");
              }, 100);
              if (err.response.status === 401) {
                that.$message.error("身份认证过期，请重新登录！");
                // 清除现有的用户信息
                commit("clearItem", { key: "user" });
                that.$router.replace({ path: "/login" });
                return;
              }
              if (warnMessage) {
                // 提示错误信息
                that.$message.error(err);
              }
              reject(err);
            });
        }
      });
    },
  },
};

export default ajax;

[
  { text: "文案内容1", category: "category1" },
  { text: "文案内容2", category: "category1" },
  { text: "文案内容3", category: "category2" },
  { text: "文案内容4", category: "category3" },
  { text: "文案内容5", category: "category2" },
];
